// client/src/hooks/useSettings.js

import useLocalStorage from './useLocalStorage';
import defaults from '../static/SettingsDefaults';

const useSettings = () => {
  const [settings, setSettings] = useLocalStorage('appSettings', defaults);
  let updated = {...settings};

  const updateMode = (newMode) => {
    setSettings(currentSettings => {
      updated = {
        ...currentSettings,
        chat: {
          ...currentSettings.chat,
          mode: newMode
        }
      }
      
      return updated;
    });
  };

  const toggleSidebar = () => {
    setSettings(currentSettings => {
      updated = {
        ...currentSettings,
        sidebar: {
          ...currentSettings.sidebar,
          isVisible: !currentSettings.sidebar.isVisible
        }
      };

      return updated;
    });
  };

  const toggleSection = (sectionName) => {
    setSettings(currentSettings => {
      updated = {
        ...currentSettings,
        sidebar: {
          ...currentSettings.sidebar,
          sections: {
            ...currentSettings.sidebar.sections,
            [sectionName]: {
              ...currentSettings.sidebar.sections[sectionName],
              isVisible: !currentSettings.sidebar.sections[sectionName].isVisible
            }
          }
        }
      };

      return updated;
    });
  };

  const updateModalViewType = (viewType) => {
    setSettings(currentSettings => {
      updated = {
        ...currentSettings,
        modal: {
          ...currentSettings.modal,
          viewType: viewType
        }
      }
      
      return updated;
    });
  };

  const toggleModal = () => {
    setSettings(currentSettings => {
      updated = {
        ...currentSettings,
        modal: {
          ...currentSettings.modal,
          isVisible: !currentSettings.modal.isVisible
        }
      };

      return updated;
    });
  };

  const updateSelectedRecord = (record) => {
    setSettings(currentSettings => {
      updated = {
        ...currentSettings,
        modal: {
          ...currentSettings.modal,
          selectedRecord: record
        }
      }
      
      return updated;
    });
  };

  return { settings,
    toggleSidebar,
    toggleSection,
    toggleModal,
    updateModalViewType,
    updateMode,
    updateSelectedRecord };
};

export default useSettings;
