// client/src/ChatContext.js

import { createContext } from "react";

const ChatContext = createContext({
  addMessage: () => { },
  clearChat: () => { },
  updateMode: () => { }
});

export default ChatContext;
