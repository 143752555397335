// client/src/components/Sidebar.js

import React, { useContext } from 'react';
import { RecordsContext } from '../RecordsContext';

const Sidebar = ({
  isSidebarVisible,
  sidebarSections,
  toggleSection,
  clearChat,
  handleModal,
  mode
}) => {
  const { records, refreshRecords } = useContext(RecordsContext);
  const { generalSection, settingsSection, recordsSection } = sidebarSections;

  const groupedRecords = records.reduce((acc, record) => {
    if (!acc[record.type]) {
      acc[record.type] = [];
    }
    acc[record.type].push(record);
    return acc;
  }, {});

  return (
    <div className={`sidebar overflow-auto bg-gradient-to-t from-stone-900 to-stone-800 to-[30px]
    ${isSidebarVisible ? 'col-span-4 md:col-span-2' : 'hidden'}`}>
      <div className="section general border-t border-stone-700">
        <h2 onClick={() => toggleSection('generalSection')}
        className={`section-header text-stone-400 hover:text-stone-200 w-full cursor-pointer transition-colors p-4 flex items-center justify-between`}>
          <span>General</span><span className="section-header--caret">{generalSection.isVisible ? "−" : "+"}</span>
        </h2>
        {generalSection.isVisible && (
          <div className="section-content p-4 pt-0">
            <button onClick={() => handleModal('projectInfo')} className="w-full max-w-[300px] text-sm text-stone-400 hover:text-stone-200 px-2 py-1 border border-stone-400 hover:border-stone-200 transition-colors">
              What Is This?
            </button>
          </div>
        )}
      </div>
      <div className="section chat-settings border-t border-stone-700">
        <h2 onClick={() => toggleSection('settingsSection')}
        className={`section-header text-stone-400 hover:text-stone-200 w-full cursor-pointer transition-colors p-4 flex items-center justify-between`}>
          <span>Settings</span><span className="section-header--caret">{settingsSection.isVisible ? "−" : "+"}</span>
        </h2>
        {settingsSection.isVisible && (
          <div className="section-content p-4 pt-0">
            <button onClick={() => clearChat()} className="w-full max-w-[300px] text-sm text-stone-400 hover:text-stone-200 px-2 py-1 border border-stone-400 hover:border-stone-200 transition-colors">
              Clear Chat
            </button>
          </div>
        )}
      </div>
      <div className="section record-settings border-t border-stone-700">
        <h2 onClick={() => toggleSection('recordsSection')}
        className={`section-header text-stone-400 hover:text-stone-200 w-full cursor-pointer transition-colors p-4 flex items-center justify-between`}>
          <span>Records</span><span className="section-header--caret">{recordsSection.isVisible ? "−" : "+"}</span>
        </h2>
        {recordsSection.isVisible && (
          <div className="section-content p-4 pt-0">
            {mode === 'input' && (
              <>
                <button onClick={() => handleModal('recordInput')} className="w-full max-w-[300px] text-sm text-stone-400 hover:text-stone-200 px-2 py-1 mb-2 border border-stone-400 hover:border-stone-200 transition-colors">
                  Create Record
                </button>
                <button onClick={refreshRecords} className="w-full max-w-[300px] text-sm text-stone-400 hover:text-stone-200 px-2 py-1 mb-4 border border-stone-400 hover:border-stone-200 transition-colors">
                  Refresh Records
                </button>
              </>
            )}
            {Object.entries(groupedRecords).map(([type, groupRecords]) => (
              <div className="record-group" key={type}>
                <h3 className="text-sm text-stone-400">{type.charAt(0).toUpperCase() + type.slice(1)}:</h3>
                <ul className="my-2">
                  {groupRecords.map(record => (
                    <li
                      key={record.id}
                      onClick={() => handleModal(mode === 'input' ? 'recordInput' : 'recordSummary', record)}
                      className="text-xs cursor-pointer border-l border-stone-400 hover:border-stone-200 hover:ml-1.5 pl-3 py-1.5 text-stone-400 hover:text-stone-200 transition-all"
                    >
                      {record.name} {mode === 'input' && <span className="text-stone-500">({record.confidence}%)</span>}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
