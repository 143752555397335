// client/src/hooks/useWebSocket.js

import { useState, useEffect, useRef } from 'react';

/**
 * Custom hook to manage a WebSocket connection.
 * 
 * @param {string} wsUrl - The WebSocket URL to connect to.
 * @param {Function} handleWsData - A callback function to handle incoming WebSocket data.
 * @return {Object} - An object containing the WebSocket instance and any errors.
 */
const useWebSocket = (wsUrl, handleWsData) => {
  const [ws, setWs] = useState(null); // The WebSocket instance
  const [wsError, setWsError] = useState(null); // Error state
  const reconnectAttemptsRef = useRef(0); // Ref to keep track of reconnect attempts
  const shouldReconnect = useRef(true); // Flag to control the reconnection logic
  const maxReconnectAttempts = 10; // Maximum number of reconnection attempts
  const reconnectInterval = 5000; // Interval between reconnection attempts, in milliseconds
  const isMounted = useRef(true); // Ref to track if the component is mounted

  // Function to initialize the WebSocket connection
  const initiateWebSocketConnection = () => {
    const newWs = new WebSocket(wsUrl);

    // Event handler for successful connection
    newWs.onopen = () => {
      console.log("useWebSocket | Connected to the WebSocket");
      if (isMounted.current) {
        reconnectAttemptsRef.current = 0;
        shouldReconnect.current = true; // Reset reconnection flag on successful connection
      }
    };

    // Event handler for incoming messages
    newWs.onmessage = (event) => {
      try {
        const messageData = JSON.parse(event.data);
        handleWsData(messageData);
      } catch (err) {
        console.error("useWebSocket | Failed to parse WebSocket message:", err);
      }
    };

    // Event handler for any errors
    newWs.onerror = (event) => {
      console.error("useWebSocket | WebSocket Error:", event);
      setWsError(event); // Update error state
    };

    // Event handler for WebSocket closure
    newWs.onclose = (event) => {
      console.log("useWebSocket | WebSocket Closed:", event);
      // Attempt to reconnect if the conditions allow
      if (shouldReconnect.current && reconnectAttemptsRef.current < maxReconnectAttempts) {
        setTimeout(() => {
          console.log(`useWebSocket | Attempting to reconnect... (Attempt ${reconnectAttemptsRef.current + 1})`);
          reconnectAttemptsRef.current++;
          initiateWebSocketConnection();
        }, reconnectInterval * Math.pow(2, reconnectAttemptsRef.current));
      } else {
        console.warn("useWebSocket | Max reconnect attempts reached or not allowed to reconnect.");
      }
    };

    setWs(newWs); // Update WebSocket instance
  };

  useEffect(() => {
    initiateWebSocketConnection(); // Establish WebSocket connection on mount

    // Cleanup function to close the WebSocket and prevent memory leaks
    return () => {
      console.log('useWebSocket | Component unmounted');
      shouldReconnect.current = false; // Prevent reconnection attempts after unmounting
      if (ws) ws.close(); // Close the WebSocket connection if open
      isMounted.current = false; // Indicate the component has unmounted
    };
  }, [wsUrl, handleWsData]); // Dependencies for the effect

  return { ws, wsError }; // Return the WebSocket instance and error state
};

export default useWebSocket;

