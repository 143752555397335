// client/src/components/ChatBox.js

import React, { useEffect, useRef } from 'react';
import Message from './Message';

// ChatBox component that shows messages and a loading indicator when new messages are being fetched.
const ChatBox = ({ messages, isLoading }) => {
  // Ref for the chat box container to manage scroll behavior.
  const chatBoxRef = useRef();

  // Automatically scroll to the bottom when new messages arrive.
  useEffect(() => {
    const chatBoxCurrent = chatBoxRef.current;
    if (chatBoxCurrent) {
      chatBoxCurrent.scrollTop = chatBoxCurrent.scrollHeight;
    }
  }, [messages]);

  // Renders the chat messages and a typing indicator when loading.
  return (
    <div className="chatWrapper relative overflow-auto grow grid crid-cols-1 grid-rows-1 bg-stone-50">
      <div className="halftone"></div>
      <div className="chatBox p-4 md:px-6 overflow-auto lg:px-8 xl:px-12 flex flex-col" ref={chatBoxRef}>
      {messages.map((message, index) => (
        <Message key={index} {...message} messages={messages} messageIndex={index} />
      ))}
      {isLoading && (
        <div data-sender="assistant" className="message mb-4 py-3 animate-fadeIn">
          <div className="content text-xs text-stone-400">
            <div className="typing-indicator">
              <span>⬤</span><span>⬤</span><span>⬤</span>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default ChatBox;