// client/src/hooks/useChat.js

import { useCallback } from 'react';
import useLocalStorage from './useLocalStorage';

// Manages chat updates and functionality
const useChat = (initialMessage, mode) => {
  // Helper function to create a new message object
  const createNewMessage = (role, content) => ({
    role,
    content,
    chatMode: mode,
    timestamp: new Date().toISOString(),
  });

  // Custom hook to manage chat messages in localStorage and state
  const [messages, setMessages] = useLocalStorage('chatMessages', [createNewMessage('assistant', initialMessage)]);

  // Function to add a new message
  const addMessage = useCallback((role, content) => {
    setMessages((currentMessages) => {
      const newMessage = {
        role,
        content,
        chatMode: mode,
        timestamp: new Date().toISOString()
      };
      return [...currentMessages, newMessage];
    });
  }, [setMessages, mode]);

  // Function to clear all chat messages
  const clearChat = useCallback(() => {
    setMessages([]);
  }, [setMessages]);

  return { messages, addMessage, clearChat };
};

export default useChat;
