const recordTypes = [
  {
    type: "experience",
    namePlaceholder: "Company or organization",
    descriptionPlaceholder: "Your job title or role, duration of employment, major tasks performed, and key achievements."
  },
  {
    type: "education",
    namePlaceholder: "School or course",
    descriptionPlaceholder: "Degree or certification obtained, major subjects or courses taken, and key achievements."
  },
  {
    type: "skills",
    namePlaceholder: "Skill or ability",
    descriptionPlaceholder: "Level of proficiency (e.g., beginner, intermediate, expert), and projects where the skill was applied."
  },
  {
    type: "Interests",
    namePlaceholder: "Interest or hobby",
    descriptionPlaceholder: "Detailed description, any professional relevance, and achievements related to this interest."
  },
  {
    type: "goals",
    namePlaceholder: "Aspiration or future plan",
    descriptionPlaceholder: "Outline your short or long-term goal, and strategies or action plans to achieve it."
  },
  {
    type: "projects",
    namePlaceholder: "Name of project",
    descriptionPlaceholder: "Duration of the project, team size, your specific role, technologies or tools used, outcomes, and challenges faced."
  },
  {
    type: "publications",
    namePlaceholder: "Title of publication",
    descriptionPlaceholder: "Key content or findings, where it was published (e.g., journal, blog), and significance of the work."
  },
  {
    type: "awards",
    namePlaceholder: "Award or honor",
    descriptionPlaceholder: "Detailed description of the recognition, reason or criteria for the award, and its relevance to your professional growth."
  },
  {
    type: "certifications",
    namePlaceholder: "Name of certification",
    descriptionPlaceholder: "Issuing institution or body, relevance to your field, application of knowledge gained from the certification."
  },
  {
    type: "languages",
    namePlaceholder: "Language spoken",
    descriptionPlaceholder: "Proficiency level (e.g., native, fluent, intermediate, basic), experiences or scenarios where the language was professionally used."
  },
  {
    type: "affiliations",
    namePlaceholder: "Organization or group",
    descriptionPlaceholder: "Your specific role, duration of membership, relevance to your professional network or growth."
  },
  {
    type: "testimonials",
    namePlaceholder: "Name of reference",
    descriptionPlaceholder: "Specific feedback about your work ethic, unique skills, contributions to projects, or interpersonal relationships."
  },
  {
    type: "Conferences",
    namePlaceholder: "Conference or workshop",
    descriptionPlaceholder: "Details of the event, your role (e.g., attendee, speaker, panelist), key sessions attended or topics covered, and takeaways."
  },
  {
    type: "Volunteering",
    namePlaceholder: "Organization or event",
    descriptionPlaceholder: "Your role or responsibilities, duration of service, contributions made, and the wider impact of your volunteering work.,,/"
  }
];

export default recordTypes;
