import React from 'react';
import projectInfoContent from '../static/ProjectInfoContent';

function ProjectInfo() {
  return (
    <>
      <h1 className="text-xl text-stone-800 font-bold mb-2">{projectInfoContent.title}</h1>
      <p className="text-sm text-stone-700 mb-2 text-justify">{projectInfoContent.description}</p>

      {projectInfoContent.sections.map((section, index) => (
        <div key={index}>
          <h2 className={section.className}>{section.title}</h2>
          <p className="text-sm text-stone-700 mb-2 text-justify">{section.content}</p>

          {section.subSections && section.subSections.map((subSection, subIndex) => (
            <div key={subIndex}>
              <h3 className={subSection.className}>{subSection.title}</h3>
              <p className="text-sm text-stone-700 mb-2 text-justify">{subSection.content}</p>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

export default ProjectInfo;
