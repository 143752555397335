// client/src/hooks/useApi.js

import { useState, useCallback } from 'react';
import api from '../utils/api';

const useApi = (addMessage) => {
  const [isLoading, setIsLoading] = useState(false);

  const refreshAllRecords = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.post('/record/refresh');
      if (response.status === 200) {
        addMessage('system', 'Records have been successfully refreshed.');
      } else {
        throw new Error('Failed to refresh records.');
      }
    } catch (error) {
      addMessage('system', 'An error occurred while refreshing records. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [addMessage]);

  const sendQuestion = useCallback(async (question, mode, messages) => {
    setIsLoading(true);
    try {
      const response = await api.post('/ask', { question, mode, messages });
      return response.data.response;
    } catch (error) {
      addMessage('system', 'An error occurred while processing your message, please try rephrasing your question.');
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [addMessage]);

  return { isLoading, refreshAllRecords, sendQuestion };
};

export default useApi;
