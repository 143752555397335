// client/src/components/Popup.js

import React from 'react';

const Popup = React.forwardRef(({ onCopyMessage, onCopyHistory }, ref) => (
  <div className="popup bg-stone-800 px-2 flex flex-col items-start" ref={ref}>
    <button className="leading-4 pt-2 pb-1 text-stone-400 hover:text-stone-200 transition-colors" onClick={onCopyMessage}>copy message</button>
    <button className="leading-4 pt-1 pb-3 text-stone-400 hover:text-stone-200 transition-colors" onClick={onCopyHistory}>copy history</button>
  </div>
));

export default Popup;