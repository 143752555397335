// client/src/components/Header.js

import React from 'react';
import AppLogo from './AppLogo';

const Header = ({ isSidebarVisible, toggleSidebar }) => {
  return (
    <header className="header col-span-4 md:col-span-8 xl:col-span-12 w-full bg-gradient-to-b from-stone-900 to-stone-800 to-[60px]">
      <div className="w-full px-4 py-3 sm:py-6 md:px-6 lg:px-8 xl:px-12 flex justify-between items-center">
        <div className="headerLogo flex items-center">
          <div className="h-12 w-12 max-[370px]:hidden">
            <AppLogo />
          </div>
          <h1 className="text lg sm:text-xl mb-1 text-stone-200 font-bold">gpt-interview-proxy</h1>
        </div>
        <div className="header-links flex flex-row max-[470px]:flex-col max-[370px]:ml-4">
          <button onClick={toggleSidebar} className="text-sm text-stone-400 hover:text-stone-200 w-24 py-1 border border-stone-400 hover:border-stone-200 transition-colors">
            { !isSidebarVisible ? <span>Open Menu</span> : <span>Close Menu</span> }
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
