const projectInfoContent = {
  title: "About GPT Interview Proxy",
  description: "The GPT Interview Proxy is an innovative web application leveraging OpenAI's GPT technology to simulate interactive interviews. It's designed to represent individuals' professional backgrounds and skills, aiding employers in pre-screening candidates effectively.",
  sections: [
    {
      title: "Concept",
      content: "The application provides a conversational interface where users can engage with a virtual proxy that responds to typical interview questions. The proxy's responses are generated to reflect the individual's qualifications and experiences, offering a realistic impression of a candidate's interview performance.",
      className: "text-lg text-stone-800 font-bold"
    },
    {
      title: "Technical Overview",
      className: "text-lg text-stone-800 font-bold",
      subSections: [
        {
          title: "Frontend Development:",
          content: "Built with React for its dynamic capabilities and Tailwind CSS for custom, responsive design, the UI offers an engaging user experience that adapts to various devices and screen sizes.",
          className: "text-base text-stone-800 font-bold"
        },
        {
          title: "Backend Integration:",
          content: "The server, crafted with Node.js and Express, interacts seamlessly with a PostgreSQL database, which stores the candidate data. User inputs are processed to derive the context and fetch appropriate responses from the GPT model.",
          className: "text-base text-stone-800 font-bold"
        },
        {
          title: "Natural Language Processing:",
          content: "Utilizing OpenAI's GPT-3.5-turbo, the application interprets queries and produces responses that are coherent and contextually relevant, showcasing the advanced capabilities of NLP.",
          className: "text-base text-stone-800 font-bold"
        },
        {
          title: "Data Management and Security:",
          content: "Data integrity and security are paramount in the design. The system features robust error handling and sanitizes data to prevent injection attacks and other vulnerabilities.",
          className: "text-base text-stone-800 font-bold"
        },
        {
          title: "Accessibility and Performance:",
          content: "The application is optimized for accessibility, ensuring a wide audience can interact with the proxy. Performance enhancements and best coding practices have been implemented to ensure a smooth experience.",
          className: "text-base text-stone-800 font-bold"
        }
      ]
    },
    {
      title: "Disclaimer",
      content: "While the GPT Interview Proxy is a powerful tool for simulating interviews, it is not intended to replace human judgment. It serves as a supplement to traditional hiring processes, providing an innovative approach to candidate evaluation.",
      className: "text-lg text-stone-800 font-bold"
    }
  ]
};

export default projectInfoContent;
