// client/src/components/UserInput.js

import React, { useState, useEffect, useRef } from 'react';

// UserInput component for sending messages.
const UserInput = ({ onSendMessage, isLoading, isDisabled, isSidebarVisible }) => {
  const [input, setInput] = useState('');
  const inputRef = useRef();

  // Event handler for focusing the input field when a key is pressed.
  const handleKeyPress = (e) => {
    if (!isDisabled && /\S/.test(e.key) && inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Add keypress event listener to focus the input field.
  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [isDisabled]);

  // Automatically focus the input field when it is not loading and not disabled.
  useEffect(() => {
    if (!isLoading && !isDisabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLoading, isDisabled]);

  // Handles the form submission.
  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedInput = input.trim();
    if (trimmedInput) {
      onSendMessage(trimmedInput);
      setInput('');
    }
  };

  // Renders the user input form.
  return (
    <form className={`Chat-input bg-gradient-to-t from-stone-900 to-stone-800 to-[30px] px-4 py-2 md:px-6 lg:px-8 xl:px-12 flex justify-between items-center ${isSidebarVisible ? 'border-r border-stone-700' : ''}`} onSubmit={handleSubmit}>
      <input
        ref={inputRef}
        type="text"
        id="interviewInput"
        className={`w-full p-2 bg-transparent text-stone-200 ${isLoading ? 'thinking' : ''}`}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder={isLoading ? "Thinking..." : "Type your message..."}
        readOnly={isLoading || isDisabled}
      />
      <button
        type="submit"
        className="text-sm text-stone-400 enabled:hover:text-stone-200 transition-colors px-2 py-1 border border-stone-400 enabled:hover:border-stone-200 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isLoading}
      >
        Send
      </button>
    </form>
  );
};

export default UserInput;