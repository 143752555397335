// client/src/components/Modal.js

import React, { useState, useEffect } from 'react';
import RecordTypes from '../static/RecordTypes';
import ProjectInfo from './ProjectInfo';
import RecordInput from './RecordInput';
import RecordSummary from './RecordSummary';

function Modal({ isModalOpen, handleModal, viewType, selectedRecord, updateSelectedRecord }) {
  const [inputMode, setInputMode] = useState('add');
  const [recordType, setRecordType] = useState(RecordTypes[0].type);
  const [recordName, setRecordName] = useState("");
  const [recordDescription, setRecordDescription] = useState("");

  useEffect(() => {
    if (selectedRecord) {
      setInputMode('update');
      setRecordType(selectedRecord.type);
      setRecordName(selectedRecord.name);
      setRecordDescription(selectedRecord.description);
    } else {
      setInputMode('add')
      setRecordType(RecordTypes[0].type);
      setRecordName('');
      setRecordDescription('');
    }
  }, [selectedRecord]);

  // Logic to check for unsaved changes
  const confirmClose = () => window.confirm("You have unsaved changes. Are you sure you want to close?");
  const hasUnsavedChanges = () => {
    if (viewType !== 'recordInput') return false;

    const hasNameChanged = selectedRecord ? (recordName !== selectedRecord.name) : recordName !== '';
    const hasDescriptionChanged = selectedRecord ? (recordDescription !== selectedRecord.description) : recordDescription !== '';
    return hasNameChanged || hasDescriptionChanged;
  };

  const handleCloseModal = () => {
    if (viewType === 'recordInput' && hasUnsavedChanges()) {
      if (confirmClose()) {
        handleModal();
      }
    } else {
      handleModal();
    }
  };

  return (
    isModalOpen && (
      <div className="modal fixed top-0 left-0 w-full h-full z-10 flex items-center justify-center bg-gradient-to-b from-transparent md:from-stone-900/25 via-stone-900/50 md:via-stone-900/50 md:to-stone-900/25">
        <div className="modal-content bg-gradient-to-b from-stone-200 to-stone-100 to-[60px] relative w-5/6 max-w-[750px] md:w-3/4 lg:w-3/5 xl:w-1/2 max-h-4/5">
          <button 
            className="text-xl absolute -top-2 -right-2 bg-stone-600 hover:bg-stone-400 transition-colors text-stone-200 flex justify-center items-center h-8 w-8 shadow-md" 
            onClick={handleCloseModal}
          >✗</button>
          <div className="h-full pt-7 p-4 md:p-6 overflow-scroll max-h-[80vh]">
            {viewType === 'projectInfo' ? (
              <ProjectInfo />
            ) : viewType === 'recordInput' ? (
              <RecordInput
                  handleModal={handleModal}
                  recordType={recordType}
                  setRecordType={setRecordType}
                  recordName={recordName}
                  setRecordName={setRecordName}
                  recordDescription={recordDescription}
                  setRecordDescription={setRecordDescription}
                  inputMode={inputMode}
                  selectedRecord={selectedRecord}
              />
            ) : viewType === 'recordSummary' ? (
              <RecordSummary record={selectedRecord} />
            ) : null}
          </div>
        </div>
      </div>
    )
  );
}

export default Modal;
