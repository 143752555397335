// client/src/hooks/useBracketedInput.js

import { useContext } from 'react';
import ChatContext from '../ChatContext';
import { RecordsContext } from '../RecordsContext';
import patterns from '../static/CommandPatterns';
import useAuth from './useAuth';

const useBracketedInput = (mode, handleModal) => {
  const { addMessage, clearChat, updateMode } = useContext(ChatContext);
  const { refreshRecords } = useContext(RecordsContext);
  const { handleAuthentication } = useAuth(addMessage, updateMode);

  const handleBracketedInput = async (input) => {
    if (patterns.inputMode.test(input)) await handleAuthentication(input);
    else if (patterns.interviewMode.test(input)) updateMode('interview');
    else if (patterns.createRecord.test(input) && mode === 'input') handleModal('recordInput');
    else if (patterns.clearChat.test(input)) clearChat();
    else if (patterns.refreshRecords.test(input) && mode === 'input') { await refreshRecords() }
    else addMessage('system', `Unrecognized bracketed command: ${input}`);
  };

  return handleBracketedInput;
};

export default useBracketedInput;
