// client/src/hooks/useLocalStorage.js

import { useState } from 'react';

// Synchronizes state to localStorage
function useLocalStorage(key, initialValue) {

  // Check if value is object
  const isObj = item => typeof item === 'object';

  // Check if a string is JSON-parsable
  const isJson = item => {
    let value = typeof item !== "string" ? JSON.stringify(item) : item;
    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return (typeof value === 'object' || typeof value === 'boolean') && value !== null;
  }

  // Helper function to set item in localStorage
  const setItem = value => {
    window.localStorage.setItem(key, isObj(value) ? JSON.stringify(value) : value);
  }

  // State to store the value
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Check if item already is set on localStorage
      const item = window.localStorage.getItem(key);

      // if it exists on localStorage, parse the value and return it
      if (item) {
        return isJson(item) ? JSON.parse(item) : item

      // otherwise save the intitialValue to localStorage and return it
      } else {
        setItem(initialValue);
        return initialValue;
      }
    } catch (error) {
      console.error(`Error reading key "${key}" from localStorage:`, error);
      return initialValue;
    }
  });

  // Function to update state and save to localStorage
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have the same API as useState
      setStoredValue((prev) => {
        const valueToStore = value instanceof Function ? value(prev) : value;
        setItem(valueToStore);
        return valueToStore;
      });
    } catch (error) {
      console.error(`Error setting key "${key}" in localStorage:`, error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
