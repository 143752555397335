// client/src/components/Avatar.js

import React from 'react';
import { ReactComponent as UserAvatarSVG } from '../assets/user_avatar.svg';
import { ReactComponent as ProxyAvatarSVG } from '../assets/proxy_avatar.svg';
import { ReactComponent as SystemAvatarSVG } from '../assets/system_avatar.svg';

function Avatar(sender) {
  if (sender.role === 'system') {
    return <SystemAvatarSVG />
  } else if (sender.role === 'user') {
    return <UserAvatarSVG />
  } else {
    return <ProxyAvatarSVG />
  }
};

export default Avatar;