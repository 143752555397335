// client/src/components/Message.js

import React from 'react';
import parse from 'html-react-parser';
import Avatar from './Avatar';
import Popup from './Popup';
import useMessageAnimation from '../hooks/useMessageAnimation';
import usePopup from '../hooks/usePopup';

const Message = ({ role, chatMode, content, timestamp, messages, messageIndex }) => {
  // Hooks for animations and popup logic
  const { isMessageAnimated, isAvatarAnimated } = useMessageAnimation();
  const { showPopup, setShowPopup, popupRef } = usePopup();

  // Function to copy a single message to the clipboard
  const onCopyMessage = (message) => {
    navigator.clipboard.writeText(`[${new Date(message.timestamp).toLocaleString()}] ${message.role === 'assistant' ? 'Narcissus' : 'User'}: "${message.content}"`);
    setShowPopup(false);
  };

  // Function to copy the entire conversation up to this message
  const onCopyHistory = () => {
    const history = messages.slice(0, messageIndex + 1).map(message => (
      `[${new Date(message.timestamp).toLocaleString()}] ${message.role === 'assistant' ? 'Narcissus' : 'User'}: "${message.content}"`
    )).join('\n');

    navigator.clipboard.writeText(history);
    setShowPopup(false);
  };

  // Helper functions to determine classes
  const getMessageClasses = (role, chatMode, isMessageAnimated) => `message mb-4 py-3 px-4 flex flex-col w-fit max-w-[80%] relative bg-gradient-to-b to-[30px] ${role === 'user' ? 'from-sky-400 to-sky-300 items-end self-end' :
      role === 'assistant' ? `${chatMode === 'interview' ? 'from-lime-400 to-lime-300' : 'from-amber-400 to-amber-300'} items-start self-start` :
        'from-stone-700 to-stone-600 items-start self-start'
    } ${isMessageAnimated ? 'animate-fadeIn' : ''}`;

  const getAvatarClasses = (role, isAvatarAnimated) => `avatar cursor-context-menu w-12 h-12 bg-stone-300 hover:bg-stone-200 transition-colors p-2 absolute shadow-md shadow-md -top-2 ${role === 'user' ? 'user-avatar -right-2' :
      role === 'assistant' ? 'proxy-avatar -left-2' :
        'system-avatar -left-2'
    } ${isAvatarAnimated ? 'animate-fadeIn' : ''}`;

  const getMetadataClasses = (role, chatMode) => `flex max-[400px]:flex-col text-xs italic ${role === 'user' ? 'text-sky-800 self-start mr-10 text-left' :
      role === 'assistant' ? `${chatMode === 'interview' ? 'text-lime-800' : 'text-amber-800'} self-end ml-10 text-right` :
        'text-stone-400 self-end ml-10 text-right'
    }`;

  const getTextContentClasses = (role, chatMode) => `content text-sm md:text-base text-justify max-[400px]:pt-2 pt-5 ${role === 'user' ? 'text-sky-950' :
      role === 'assistant' ? `${chatMode === 'interview' ? 'text-lime-950' : 'text-amber-950'}` :
        'text-stone-200'
    }`;

  // Renders the message container and content with conditional formatting
  return (
    <div data-sender={role} className={getMessageClasses(role, chatMode, isMessageAnimated)}>
      <div className={getAvatarClasses(role, isAvatarAnimated)} onClick={() => setShowPopup(!showPopup)}>
        <Avatar role={role} />
      </div>
      {showPopup && (
        <div className={`absolute top-3 ${role === 'user' ? 'right-12 before:-right-[16px]' : 'left-12 before:-left-[16px]'}
        before:content-[' '] before:absolute before:border-[16px] before:border-transparent before:border-t-stone-800`}>
          <Popup
            role={role}
            ref={popupRef}
            onCopyMessage={() => onCopyMessage({ timestamp, role, content })}
            onCopyHistory={onCopyHistory}
          />
        </div>
      )}
      <div className={getMetadataClasses(role, chatMode)}>
        <span className={'author'}>
          {role === 'user' ? 'User' : role === 'system' ? 'System' : 'Narcissus'}
        </span>
        <span className={'max-[400px]:hidden px-1'}> | </span>
        <span className={'timestamp'}>
          {parse(new Date(timestamp).toLocaleString().split(', ').map(str => `<span className="whitespace-nowrap">${str}</span>`).join(' '))}
        </span>
      </div>
      <div className={getTextContentClasses(role, chatMode)}>
        <p className="whitespace-pre-line">{content}</p>
      </div>
    </div>
  );
};

export default Message;