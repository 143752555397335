// client/src/RecordsContext.js

import React, { createContext, useReducer, useCallback, useEffect } from 'react';
import useWebSocket from './hooks/useWebSocket';

export const RecordsContext = createContext();

// Records reducer to manage state updates
const recordsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_RECORDS':
      return action.payload;
    case 'UPDATE_RECORD':
      // Logic to update a single record
      return state.map((record) =>
        record.id === action.payload.id ? action.payload : record
      );
    case 'ADD_RECORD':
      // Logic to add a new record
      return [...state, action.payload];
    case 'DELETE_RECORD':
      // Logic to delete a record
      return state.filter((record) => record.id !== action.payload.id);
    default:
      return state;
  }
};

export const RecordsProvider = ({ children }) => {
  const [records, dispatch] = useReducer(recordsReducer, []);

  // Handler for WebSocket data
  const handleWebSocketData = useCallback((data) => {
    if (data.type === 'RECORDS_UPDATE') {
      // Dispatch an action to update records based on WebSocket data
      dispatch({ type: 'SET_RECORDS', payload: data.records });
      console.log('Updated records:', data);
    }
  }, []);

  // WebSocket setup
  const wsUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_WS_URL_PROD
    : process.env.REACT_APP_WS_URL_DEV || 'ws://localhost:3001';
  const { ws, wsError } = useWebSocket(wsUrl, handleWebSocketData);

  // Fetch records from the server and update the context
  const refreshRecords = useCallback(async () => {
    try {
      const response = await fetch('/api/record/all');
      const data = await response.json();
      dispatch({ type: 'SET_RECORDS', payload: data });
    } catch (err) {
      console.error('Error fetching records:', err);
    }
  }, []);

  // Initial fetch of records on component mount
  useEffect(() => {
    refreshRecords();
  }, [refreshRecords]);

  // Context provider value
  const contextValue = {
    records,
    addRecord: (record) => dispatch({ type: 'ADD_RECORD', payload: record }),
    updateRecord: (record) => dispatch({ type: 'UPDATE_RECORD', payload: record }),
    deleteRecord: (id) => dispatch({ type: 'DELETE_RECORD', payload: { id } }),
    refreshRecords
  };

  return (
    <RecordsContext.Provider value={contextValue}>
      {children}
    </RecordsContext.Provider>
  );
};

export default RecordsProvider;