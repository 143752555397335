// client/src/components/RecordInput.js

import React, { useContext, useEffect } from 'react';
import RecordTypes from '../static/RecordTypes';
import ChatContext from '../ChatContext';

const TypeInput = ({ recordType, setRecordType }) => {
  return (
    <div className="type-input w-full md:w-1/3 md:mr-2 mb-4 md:mb-0">
      <label className="block w-full text-stone-600 mb-2" htmlFor="recordType">Record Type:</label>
      <select
        className="w-full px-2 py-1 h-10 bg-white border-r-8 border-white"
        value={recordType}
        onChange={e => setRecordType(e.target.value)}
        id="recordType"
      >
        {RecordTypes.map(record => (
          <option key={record.type} value={record.type}>
            {record.type.charAt(0).toUpperCase() + record.type.slice(1)}
          </option>
        ))}
      </select>
    </div>
  );
}

const NameInput = ({ recordName, setRecordName, currentType }) => {
  return (
    <div className="name-input w-full md:w-2/3 md:ml-2">
      <label className="block text-stone-600 mb-2 w-full" htmlFor="recordName">Record Name:</label>
      <input
        className="w-full px-2 py-1 h-10 bg-white"
        type="text"
        value={recordName}
        onChange={e => setRecordName(e.target.value)}
        id="recordName"
        placeholder={currentType.namePlaceholder}
      />
    </div>
  );
}

const DescriptionInput = ({ recordDescription, setRecordDescription, currentType }) => {
  return (
    <div className="details-input w-full mb-4">
      <label className="block text-stone-600 mb-2 w-full" htmlFor="recordDescription">Description:</label>
      <textarea
        className="w-full min-h-[140px] max-h-[400px] px-2 py-1 bg-white"
        type="text"
        value={recordDescription}
        onChange={e => setRecordDescription(e.target.value)}
        id="recordDescription"
        placeholder={currentType.descriptionPlaceholder}
      />
    </div>
  );
}

function RecordInput({
  handleModal,
  recordType,
  setRecordType,
  recordName,
  setRecordName,
  recordDescription,
  setRecordDescription,
  inputMode,
  selectedRecord }) {
  const currentType = RecordTypes.find(r => r.type === recordType);
  const { addMessage } = useContext(ChatContext);
  const hasRecordChanged = () => {
    if (!selectedRecord) return true;
    return selectedRecord.type !== recordType ||
      selectedRecord.name !== recordName ||
      selectedRecord.description !== recordDescription;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMode === 'update' && !hasRecordChanged()) {
      handleModal();
      return;
    }

    if (recordType && recordName) {
      try {
        let endpoint, method, successMessage;

        if (inputMode === 'add') {
          endpoint = "/api/record/add";
          method = "POST";
          successMessage = "New record created";
        } else if (inputMode === 'update' && selectedRecord) {
          endpoint = `/api/record/update/${selectedRecord.id}`;
          method = "PUT";
          successMessage = "Record updated";
        } else {
          throw new Error("Invalid input mode or missing selected record");
        }

        const response = await fetch(endpoint, {
          method: method,
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            recordType,
            recordName,
            recordDescription
          })
        });

        if (response.ok) {
          const data = await response.json();
          const message = `${successMessage}.
        Type: ${data.type}
        Name: ${data.name}`;
          addMessage("system", message);
          handleModal();
        } else {
          addMessage("system", "Error saving record");
        }
      } catch (err) {
        addMessage("system", `Error saving record: ${err.message}`);
      }
    }
  };

  const handleDelete = async () => {
    const confirmDelete = () => window.confirm("Are you sure you want to delete this record?");

    if (selectedRecord && confirmDelete()) {
      try {
        const response = await fetch(`/api/record/delete/${selectedRecord.id}`, {
          method: "DELETE"
        });

        if (response.ok) {
          const data = await response.json();
          addMessage("system", data.message);
          handleModal();
        } else {
          const errorData = await response.json();
          addMessage("system", errorData.error || "Error deleting record");
        }
      } catch (err) {
        addMessage("system", `Error deleting record: ${err.message}`);
      }
    }
  };

  const handleBeforeUnload = (event, isOpen) => {
    console.log(isOpen);
    if (isOpen) {
      event.preventDefault();
      event.returnValue = 'You have unsaved changes! Are you sure you want to leave?';
    }
  };

  useEffect(() => {
    const handleUnload = (event) => handleBeforeUnload(event, hasRecordChanged());
    window.addEventListener('beforeunload', handleUnload);

    // Cleanup
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [recordType, recordName, recordDescription, selectedRecord]);

  return (
    <>
      <h1 className="text-xl text-stone-800 font-bold mb-2">{inputMode === 'update' ? "Update Record" : "Add New Record"}</h1>
      <form className="w-full flex flex-col items-stretch" onSubmit={handleSubmit}>
        <div className="mb-4 flex flex-col md:flex-row">
          <TypeInput recordType={recordType} setRecordType={setRecordType} />
          <NameInput recordName={recordName} setRecordName={setRecordName} currentType={currentType} />
        </div>
        <DescriptionInput recordDescription={recordDescription} setRecordDescription={setRecordDescription} currentType={currentType} />
        <div className="flex max-[500px]:flex-col flex-row justify-center items-center">
        <button type="submit" disabled={!recordName || !recordDescription} className="w-full max-w-[300px] max-[500px]:mb-4 px-4 py-2 mx-4 bg-stone-400 enabled:bg-stone-600 enabled:hover:bg-stone-400 text-stone-200 transition-colors enabled:shadow-md font-semibold disabled:cursor-not-allowed">Submit</button>
        {inputMode === 'update' &&
          <button type="button" onClick={handleDelete} className="w-full max-w-[300px] px-4 py-2 mx-4 bg-red-600 enabled:hover:bg-red-400 text-red-100 transition-colors shadow-md font-semibold disabled:cursor-not-allowed">Delete</button>
        }
        </div>
      </form>
    </>
  );
}

export default RecordInput;
