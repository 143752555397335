// client/src/components/RecordSummary.js

import React from 'react';

function RecordSummary({ record }) {
  return (
    <>
      <h1 className="text-xl text-stone-800 font-bold mb-2">{record.name}</h1>
      <h2 className="text-lg text-stone-700 mb-2">{record.type}</h2>
      <p className="text-sm text-stone-700 mb-2 text-justify">{record.summary}</p>
    </>
  );
}

export default RecordSummary;
