// client/src/hooks/useMessageAnimation.js

import { useState, useEffect } from 'react';

const useMessageAnimation = () => {
  const [isMessageAnimated, setMessageAnimated] = useState(false);
  const [isAvatarAnimated, setAvatarAnimated] = useState(false);

  useEffect(() => {
    setMessageAnimated(true);
    const timeoutId = setTimeout(() => {
      setAvatarAnimated(true);
    }, 400);

    return () => clearTimeout(timeoutId);
  }, []);

  return { isMessageAnimated, isAvatarAnimated };
};

export default useMessageAnimation;
