// client/src/hooks/useAuth.js
import { useCallback } from 'react';
import api from '../utils/api';
import patterns from '../static/CommandPatterns';

const useAuth = (addMessage, updateMode) => {
  const handleAuthentication = useCallback(async (input) => {
    const password = patterns.password.exec(input)?.groups['pass'];
    if (password) {
      try {
        const response = await api.post('/authenticate', { password });
        if (response.data.authenticated) {
          updateMode('input');
        } else {
          throw new Error('Incorrect password');
        }
      } catch (error) {
        addMessage('system', error.message);
      }
    } else {
      addMessage('system', 'Missing password');
    }
  }, [addMessage, updateMode]);

  return { handleAuthentication };
};

export default useAuth;
