// client/src/components/Chat.js

import React, { useContext } from 'react';
import sanitizeHtml from 'sanitize-html';
import ChatContext from '../ChatContext';
import useApi from '../hooks/useApi';
import useBracketedInput from '../hooks/useBracketedInput';
import ChatBox from './ChatBox';
import UserInput from './UserInput';

// Main Chat component where user messages are processed and sent.
const Chat = ({ isSidebarVisible, mode, messages, isModalOpen, handleModal }) => {
  // Chat context for managing messages and chat state.
  const { addMessage } = useContext(ChatContext);

  // Custom hooks for API calls and handling bracketed commands.
  const { isLoading, sendQuestion } = useApi(addMessage);
  const handleBracketedInput = useBracketedInput(mode, handleModal);

  // Sanitizes user input to prevent XSS attacks.
  const sanitizeInput = input => sanitizeHtml(input, { allowedTags: [], allowedAttributes: {} }).trim();

  // Checks if the user input is within brackets to trigger special commands.
  const isBracketedInput = (input) => {
    const bracketedPattern = /^\[.*\]$/;
    return bracketedPattern.test(input);
  };

  // Handles sending messages, both bracketed commands and regular queries.
  const handleSendMessage = async (input) => {
    const sanitizedInput = sanitizeInput(input);
    if (!sanitizedInput) return;

    if (isBracketedInput(sanitizedInput)) {
      await handleBracketedInput(sanitizedInput);
    } else {
      addMessage('user', sanitizedInput);
      const response = await sendQuestion(sanitizedInput, mode, messages);
      if (response) {
        addMessage('assistant', response);
      }
    }
  };

  // Renders the chat UI components.
  return (
    <div className={`col-span-4 flex-col shadow-inner overflow-hidden ${isSidebarVisible ? 'hidden md:flex md:col-span-6 xl:col-span-10' : 'flex md:col-span-8 xl:col-span-12'}`}>
      <ChatBox messages={messages} isLoading={isLoading} />
      <UserInput onSendMessage={handleSendMessage} isLoading={isLoading} isDisabled={isModalOpen} messages={messages} isSidebarVisible={isSidebarVisible} />
    </div>
  );
};

export default Chat;
