// client/src/static/CommandPatterns.js

const patterns = {
  interviewMode: /(?<=\[\s*)mode:\s*interview(?=,{0,1}[\s\]])/,
  inputMode: /(?<=\[\s*)mode:\s*input(?=,{0,1}[\s\]])/,
  password: /(?<=\s+)pass:\s*(?<pass>[^\s\]]*)(?=,{0,1}[\s\]])/,
  createRecord: /(?<=\[\s*)records:\s*create(?=,{0,1}[\s\]])/,
  refreshRecords: /(?<=\[\s*)records:\s*refresh(?=,{0,1}[\s\]])/,
  clearChat: /(?<=\[\s*)chat:\s*clear(?=,{0,1}[\s\]])/
};

export default patterns;