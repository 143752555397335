// client/src/App.js

import React, { useEffect, useState, useRef } from 'react';
import ChatContext from './ChatContext';
import { RecordsProvider } from './RecordsContext';
import { initialMessages } from './static/StaticMessages';
import useChat from './hooks/useChat';
import useSettings from './hooks/useSettings';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Chat from './components/Chat';
import Modal from './components/Modal';
import './App.css';

function App() {
  // Settings state hook
  const {
    settings,
    toggleSidebar,
    toggleSection,
    toggleModal,
    updateModalViewType,
    updateMode,
    updateSelectedRecord
  } = useSettings();
  const { chat, modal, sidebar } = settings;
  const { mode } = chat;
  const { isVisible: isModalOpen, viewType: modalViewType, selectedRecord } = modal;
  const { isVisible: sidebarVisible, sections: sidebarSections } = sidebar;
  const [shouldToggleModal, setShouldToggleModal] = useState(false);

  // Ref for app container to manage resize events
  const appContainerRef = useRef(null);

  // Custom hooks for managing chat
  const { messages, addMessage, clearChat } = useChat(initialMessages[mode], mode);

  // Event handling for app-wide effects
  const handleResize = (ref) => {
    if (ref.current) {
      ref.current.style.height = `${window.innerHeight}px`;
    }
  };

  useEffect(() => {
    // Set initial app height and attach event listeners
    handleResize(appContainerRef);
    window.addEventListener('resize', () => handleResize(appContainerRef));

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', () => handleResize(appContainerRef));
    };
  }, []);

  const updateChatMode = (newMode) => {
    if (newMode !== mode) updateMode(newMode);
    addMessage('system', `Mode set to "${newMode}"`);
  };

  const handleModal = (viewType = null, record = null) => {
    if (viewType) updateModalViewType(viewType);
    if (record) updateSelectedRecord(record);
    setShouldToggleModal(true);
  };

  useEffect(() => {
    if (shouldToggleModal) {
      toggleModal();
      setShouldToggleModal(false);
    }
  }, [shouldToggleModal, toggleModal]);

  // Render the application UI
  return (
    <ChatContext.Provider value={{ addMessage, clearChat, updateMode: updateChatMode }}>
      <RecordsProvider>
        <div ref={appContainerRef} className="App grid grid-rows-[auto_1fr] grid-cols-4 md:grid-cols-8 xl:grid-cols-12" style={{ height: '100vh' }}>
          <Header
            isSidebarVisible={sidebarVisible}
            toggleSidebar={toggleSidebar} />
          <Modal
            isModalOpen={isModalOpen}
            handleModal={handleModal}
            viewType={modalViewType}
            selectedRecord={selectedRecord}
            updateSelectedRecord={updateSelectedRecord} />
          <Chat
            mode={mode}
            messages={messages}
            isSidebarVisible={sidebarVisible}
            isModalOpen={isModalOpen}
            handleModal={handleModal} />
          <Sidebar
            mode={mode}
            clearChat={clearChat}
            isSidebarVisible={sidebarVisible}
            sidebarSections={sidebarSections}
            toggleSection={toggleSection}
            handleModal={handleModal} />
        </div>
      </RecordsProvider>
    </ChatContext.Provider>
  );
}

export default App;