// client/src/static/SettingsDefaults.js

const defaults = {
  chat: {
    mode: 'interview'
  },
  modal: {
    isVisible: false,
    viewType: null,
    selectedRecord: null
  },
  sidebar: {
    isVisible: false,
    sections: {
      generalSection: { isVisible: true },
      settingsSection: { isVisible: true },
      recordsSection: { isVisible: true }
    }
  }
};

export default defaults;